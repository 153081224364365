/* Imports
***********************/
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/* Basic common styles
***********************/

html {
	--basic-text-color: #2A4454;
	--accent-text-color: #FC6728;
	--dark-text-color: #2A4454;
	--light-sky-background-color: #F0F6F9;
	--light-pink-background-color: #FCF4F1;
	--accent-blue-text-color: #0C669D;
}

html {
	overflow-x: hidden;
}

body {
	font-family: 'Kanit', sans-serif;
	color: var(--basic-text-color);
	background-color: #FFF;
	font-size: 18px;
	overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	transition: font-size .3s;
}

h1 {
	font-size: 100px;
	font-weight: 700;
	text-align: center;
	line-height: 110%;
}

h2 {
	font-size: 80px;
	font-weight: 700;
	text-align: center;
	line-height: 120%;
}

h4 {
	font-size: 40px;
	font-weight: 600;
	line-height: 130%;
}

h5 {
	font-size: 32px;
	font-weight: 400;
	line-height: 140%;
	text-align: center;
	margin: 0;
	color: var(--accent-text-color);
}

p {
	font-size: 24px;
	line-height: 140%;
}

/* Main container
***********************/

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.container-xl {
	width: 100%;
	max-width: 1285px;
	transition: max-width .3s;
}

/* Header
***********************/

.header {
	position: absolute;
	top: 0px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px 0;
}

.header__nav,
.footer__nav {
	display: flex;
	column-gap: 60px;
	cursor: pointer;
}

.nav__item_blue {
	display: flex;
	align-items: center;
	column-gap: 12px;
	color: var(--accent-blue-text-color);
}

.nav__item_blue img {
	width: 22px;
	height: 20px;
	transform: translateY(1px);
}

.header__logo {
	width: 162px;
	transition: width .3s;
}

.header-mobile {
	display: none;
	transition: background-color .3s;
}

.header-mobile__nav-wrap {
	overflow: hidden;
	width: 100%;
	height: 300px;

	position: absolute;
	right: 0px;
	top: 78.5px;

	pointer-events: none;
}

.header-mobile__nav-wrap:has(.header-mobile__nav_open) {
	pointer-events: auto;
}

.nav__item a {
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 12px;
}

.header-mobile__nav {
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;

	position: absolute;
	opacity: 0;
	right: 0px;
	width: 300px;
	height: 150px;

	transition: transform .3s;
	transform: translateX(100%);
	
	background-color: rgba(255, 255, 255, 0.438);
	backdrop-filter: blur(4px);

	opacity: 1;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}

.header-mobile__nav_open {
	transform: translateX(0%);
}

.header-mobile__nav .nav__item:not(.nav__item_blue)::after {
	content: '';
	position: absolute;
	left: 0px;
	bottom: -10px;
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #a9cde063;
}

.header-mobile__nav .nav__item {
	display: flex;
	justify-content: center;
	position: relative;
	width: 100%;
}

.header-mobile__nav a {
	display: flex;
	justify-content: center;
	width: 100%;
}


/* Burger
***********************/

.header__burger {
	width: 30px;
	height: 30px;
	position: relative;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
}


.header__burger span {
	display: block;
	position: absolute;
	height: 3px;
	width: 50%;
	background: var(--accent-blue-text-color);
	opacity: 1;
	transform: rotate(0deg);
	transition: .25s ease-in-out;
}

.header__burger span:nth-child(even) {
	left: 50%;
	border-radius: 0 3px 3px 0;
}

.header__burger span:nth-child(odd) {
	left: 0px;
	border-radius: 3px 0 0 3px;
}

.header__burgerspan:nth-child(1),
.header__burger span:nth-child(2) {
	top: 0px;
}

.header__burger span:nth-child(3),
.header__burger span:nth-child(4) {
	top: 9px;
}

.header__burger span:nth-child(5),
.header__burger span:nth-child(6) {
	top: 18px;
}

.header__burger.header__burger-open span:nth-child(1),
.header__burger.header__burger-open span:nth-child(6) {
	transform: rotate(45deg);
}

.header__burger.header__burger-open span:nth-child(2),
.header__burger.header__burger-open span:nth-child(5) {
	transform: rotate(-45deg);
}

.header__burger.header__burger-open span:nth-child(1) {
	left: 5px;
	top: 3px;
}

.header__burger.header__burger-open span:nth-child(2) {
	left: 50%;
	top: 3px;
}

.header__burger.header__burger-open span:nth-child(3) {
	left: -50%;
	opacity: 0;
}

.header__burger.header__burger-open span:nth-child(4) {
	left: 100%;
	opacity: 0;
}

.header__burger.header__burger-open span:nth-child(5) {
	left: 5px;
	top: 13px;
}

.header__burger.header__burger-open span:nth-child(6) {
	left: 50%;
    top: 13px;
}

/* Banner
***********************/

.banner {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;
	min-height: 100vh;
	background-image: url(assets/img/banner-bg.png);
	background-color: lightgray 50%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	box-shadow: inset 0 0 0 1000px rgb(240 246 250 / 69%);
	background-position-y: 0;
}

.banner__title_small {
	margin-bottom: 30px;
}

/* Banner
***********************/

.quote {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	min-height: 100vh;
	width: 100%;
}

.quote-blue {
	background-color: var(--light-sky-background-color);
}

.quote__item {
	max-width: 1066px;
	width: 100%;
	text-align: center;
}


/* Services & Process Blocks
***********************/

.services,
.process {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 200px 0 250px 0;
	background-color: var(--light-pink-background-color);
}

.services__title,
.process__title {
	margin-bottom: 20px;
}

.services__description,
.process__description {
	text-align: center;
	margin-bottom: 150px;
}

.services__service {
	display: flex;
	justify-content: center;
	gap: 80px;
	flex-wrap: wrap;
}

/* Service & Process blocks
***********************/

.service {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	max-width: 600px;
	min-height: 255px;
}

.service__svg {
	margin-bottom: 30px;
}

.service__svg_high {
	margin-bottom: 42px;
}

.service__title {
	margin-bottom: 20px;
}

.service__description {
	color: var(--dark-text-color);
	opacity: 0.7;
	font-size: 20px;
	font-weight: 300;
}

/* Process
***********************/

.process {
	flex-direction: column;
	background-color: #FFF;
}

/* Process Item
***********************/

.item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 150px;
}

.item__text {
	min-width: 600px
}

.item:nth-child(odd) .item__img {
	margin-right: 130px;
}

.item:nth-child(even) .item__img {
	margin-left: 130px;
}

.item__img {
	width: 600px;
	height: 400px;
}

.item__title {
	margin-bottom: 20px;
}

.item__description {
	opacity: 0.7;
	font-size: 20px;
}

/* Process Item
***********************/

.footer {
	padding: 150px 0;
}

.footer__top-block {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}

.footer__bottom-block {
	display: flex;
	justify-content: space-between;
}

.footer__description,
.foooter_copyright {
	font-size: 18px;
	opacity: 0.7;
	font-weight: 300;
	line-height: 140%;
}

.footer__description {
	max-width: 512px;
}